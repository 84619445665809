import { Entity } from "@thewebops/admin-panel";

export default new Entity({
  name: "user",
  pluralName: "users",
  pathName: "users",
  endpoint: "admin/users",
  serverName: "user",
  serverPluralName: "users",
  displayName: "Users",
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  showSearch: true,
  titleField: "name",
  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "email",
      label: "Email",
      accessor: "email",
      inList: true,
      type: "email",
      required: true,
      usedToShow: true,
    },
    {
      identifier: "name",
      label: "Name",
      accessor: "name",
      inList: true,
      type: "string",
      required: true,
    },

    {
      identifier: "search_type",
      label: { en: "Search Type", ar: " الدور" },
      accessor: "search_type",
      required: true,
      type: "select",
      options: [
        {
          value: "vbg",
          label: "Vbg",
        },
        {
          value: "gcp",
          label: "GCP",
        },
      ],
    },
    {
      identifier: "databases_booleans",
      label: "Database access",
      accessor: "databases_booleans",
      type: "checkbox",
      checkboxConfig: {
        format: "string",
      },
      options: [
        { value: "1", label: "Gs1 Admin" },
        { value: "2", label: "Grp" },
        { value: "3", label: "Gepir" },
        { value: "4", label: "Gi" },
      ],
    },
    {
      identifier: "access_types_booleans",
      label: "Access Type",
      accessor: "access_types_booleans",
      type: "checkbox",
      checkboxConfig: {
        format: "string",
      },
      options: [
        { value: "1", label: "Web" },
        { value: "2", label: "API" },
      ],
    },
  ],
  filters: [],
});

import _login from "./_login";
import _forgetPassword from "./_forgetPassword";
import _resetPassword from "./_resetPassword";
import _completeProfile from "./_completeProfile";
import _register from "./_register";

const authConfig = {};

const authUserDefinedEntities = [
  _login,
  _forgetPassword,
  _resetPassword,
  _completeProfile,
  _register
]

export { authConfig, authUserDefinedEntities };

import { Entity } from "@thewebops/admin-panel";

export default (authConfig) =>
  new Entity({
    name: "register",
    title: "Register",
    subTitle: `Enter the credentials associated with your ${authConfig.displayName} account.`,
    path: "/register",
    actionLabel: "Register",
    action: "create",
    submitButtonText: "Submit",
    isFullScreen: false,
    fields: [
      {
        label: { en: "First name", ar: "نوع العميل" },
        required: true,
        type: "string",
        identifier: "first_name",
        container: true,
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        label: { en: "Second name", ar: "نوع العميل" },
        required: true,
        type: "string",
        identifier: "second_name",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        label: { en: "Third name", ar: "نوع العميل" },
        required: true,
        type: "string",
        identifier: "third_name",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        label: { en: "Last name", ar: "نوع العميل" },
        required: true,
        type: "string",
        identifier: "last_name",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "front_id_image",
        accessor: "front_id_image",
        label: { en: "Front ID Image", ar: "صورة أمامية للبطاقة" },
        required: true,
        type: "image",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "gender",
        label: { en: "Gender", ar: "النوع" },
        accessor: "gender",
        type: "select",
        options: [
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
        ],
        required: true,
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "back_id_image",
        accessor: "back_id_image",
        label: { en: "Back ID Image", ar: "صورة خلفية للبطاقة" },
        required: true,
        type: "image",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        label: { en: "Birth Date", ar: "تاريخ الميلاد" },
        required: true,
        type: "date",
        identifier: "birth_date",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },

      {
        label: { en: "Email", ar: "البريد الإلكتروني" },
        required: false,
        type: "email",
        identifier: "email",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        label: { en: "Phone Number", ar: "رقم الهاتف" },
        required: true,
        type: "string",
        identifier: "primary_phone_number",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "contact_person",
        label: { en: "Contact Person", ar: "الشخص المفوض" },
        accessor: "contact_person",
        type: "string",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "contact_person_mobile_number",
        label: { en: "Contact Person Mobile Number", ar: "رقم تليفون الشخص المفوض" },
        accessor: "contact_person_mobile_number",
        type: "string",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        label: { en: "company Name", ar: "اسم الشركة" },
        required: true,
        type: "string",
        identifier: "company_name",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "client_type",
        label: { en: "Client Type", ar: "نوع العميل" },
        accessor: "client_type",
        dependee: true,
        type: "select",
        options: [
          {
            value: "individual",
            label: { en: "Individual", ar: "أفراد" },
          },
          {
            value: "company",
            label: { en: "Company", ar: "شركات" },
          },
        ],
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "commercial_register_image",
        accessor: "commercial_register_image",
        label: "Commercial Register Image",
        required: true,
        type: "image",
        dependsOn: "client_type",
        dependsOnValue: "company",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "tax_card_image",
        accessor: "tax_card_image",
        label: "Tax Card Image",
        required: true,
        type: "image",
        dependsOn: "client_type",
        dependsOnValue: "company",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "supply_rate",
        label: { en: "Settlement Rate", ar: "معدل التوريد" },
        accessor: "supply_rate",
        type: "select",
        options: [
          {
            value: "daily",
            label: { en: "Daily", ar: "يومي" },
          },
          {
            value: "weekly",
            label: { en: "Weekly", ar: "أسبوعي" },
          },
          {
            value: "monthly",
            label: { en: "Monthly", ar: "شهري" },
          },
          {
            value: "half_monthly",
            label: { en: "Half Monthly", ar: "نصف شهري" },
          },
        ],
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "supply_rate_day",
        label: { en: "Supply Rate Day", ar: "يوم معدل التوريد" },
        accessor: "supply_rate_day",
        type: "select",
        options: [
          { value: "sunday", label: "Sunday" },
          { value: "monday", label: "Monday" },
          { value: "tuesday", label: "Tuesday" },
          { value: "wednesday", label: "Wednesday" },
          { value: "thursday", label: "Thursday" },
          { value: "friday", label: "Friday" },
          { value: "saturday", label: "Saturday" },
        ],
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "insuranse_shipments",
        label: { en: "Shipments Insurance", ar: "سياسة تأمين الشحنات" },
        accessor: "insuranse_shipments",
        type: "select",
        options: [
          { value: "true", label: { en: "True", ar: "نعم" } },
          { value: "false", label: { en: "False", ar: "لا" } },
        ],
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "pick_and_choose",
        label: { en: "Pick and Choose", ar: "سياسة فتح الشحنات" },
        accessor: "pick_and_choose",
        type: "select",
        options: [
          { value: "true", label: { en: "True", ar: "نعم" } },
          { value: "false", label: { en: "False", ar: "لا" } },
        ],
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "type_of_payment",
        label: { en: "Type of Payment", ar: "نوع الحساب" },
        accessor: "type_of_payment",
        type: "select",
        options: [
          { value: "credit", label: { en: "Credit", ar: "آجل" } },
          { value: "cash", label: { en: "Cash", ar: "نقدي" } },
        ],
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "client_addresses_attributes",
        accessor: "client_addresses",
        label: { en: "Client Addresses", ar: "عنوان العميل" },
        type: "dynamic",
        fields: [
          {
            identifier: "street_name",
            label: { en: "Street name", ar: "اسم الشارع" },
            accessor: "street_name",
            type: "string",
          },
          {
            identifier: "building_number",
            label: { en: "Building Number", ar: "رقم المبنى" },
            accessor: "building_number",
            type: "string",
          },
          {
            identifier: "floor_number",
            label: { en: "Floor Number", ar: "رقم الطابق" },
            accessor: "floor_number",
            type: "string",
          },
          {
            identifier: "apartment_number",
            label: { en: "Appartment Number", ar: "رقم الشقة" },
            accessor: "apartment_number",
            type: "string",
          },
          {
            identifier: "land_mark",
            label: "Land Mark",
            accessor: "land_mark",
            type: "string",
          },
          {
            identifier: "phone_number",
            label: { en: "Phone Number", ar: "رقم الهاتف" },
            accessor: "phone_number",
            type: "string",
          },
          {
            identifier: "address_type",
            label: { en: "Address Type", ar: "نوع العنوان" },
            accessor: "address_type",
            type: "select",
            options: [
              { value: "main", label: { en: "Main", ar: "الرئيسي" } },
              { value: "other", label: { en: "Other", ar: "آخر" } },
            ],
            required: true,
          },
          {
            identifier: "address_name",
            accessor: "address_name",
            label: "Address",
            type: "string",
          },
        ],
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        identifier: "client_numbers_attributes",
        accessor: "client_numbers",
        label: { en: "Client Numbers", ar: "رقم تليفون العميل" },
        type: "dynamic",
        fields: [
          {
            identifier: "id",
            label: "ID",
            accessor: "id",
          },
          {
            identifier: "number",
            label: { en: "Number", ar: "رقم الهاتف" },
            accessor: "number",
            type: "string",
          },
          {
            identifier: "number_type",
            label: { en: "Number Type", ar: "نوع الرقم" },
            accessor: "number_type",
            type: "select",
            options: [
              { value: "mobile", label: { en: "Mobile", ar: "محمول" } },
              { value: "home", label: { en: "Home", ar: "أرضي" } },
            ],
            required: true,
          },
        ],
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        label: { en: "Password", ar: "كلمة المرور" },
        required: true,
        type: "password",
        identifier: "password",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
      {
        label: { en: "Password confirmation", ar: "تأكيد كلمة المرور" },
        required: true,
        type: "password",
        identifier: "password_confirmation",
        smCol: 12,
        mdCol: 6,
        lgCol: 6,
      },
    ],
    requestConfig: {
      url: `/${authConfig.serverPluralName}`,
      method: "post",
      useFormData: true,
      saveToLocalStorage: true,
      successRedirectPath: "/confirm_account",
      payloadWrapper: {
        name: "client",
        fields: [
          "first_name",
          "second_name",
          "third_name",
          "last_name",
          "front_id_image",
          "back_id_image",
          "birth_date",
          "gender",
          "email",
          "primary_phone_number",
          "company_name",
          "client_type",
          "commercial_register_image",
          "tax_card_image",
          "supply_rate",
          "supply_rate_day",
          "method_of_supply",
          "bank_name",
          "branch_name",
          "type_of_account",
          "name_of_the_account",
          "account_number",
          "supply_branch_id",
          "receiving_returns",
          "insuranse_shipments",
          "pick_and_choose",
          "type_of_payment",
          "client_addresses_attributes",
          "client_numbers_attributes",
          "password",
          "password_confirmation",
        ],
      },
    },
  });

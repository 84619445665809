import { Entity } from "@thewebops/admin-panel";
import SearchResponse from "./SearchResponse";

export default new Entity({
  name: "searchResult",
  pluralName: "searchResults",
  pathName: "search_results",
  endpoint: "admin/search_results",
  serverName: "search_result",
  serverPluralName: "search_results",
  displayName: "Search Result",
  allowCreate: true,
  allowEdit: false,
  allowDelete: false,
  showRowActions: true,
  showSearch: false,
  useFormData: true,
  titleField: "name",
  entityIdentifier: "id",
  childEntities: [SearchResponse],
  customErrorKeys: ["base", "barcode"],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "response_status",
      label: "Status",
      accessor: "response_status",
      inList: true,
      type: "string",
      required: true,
      hideInCreate: true,
    },
    {
      identifier: "searchable_email",
      label: "Email",
      accessor: "searchable_email",
      inList: true,
      type: "string",
      hideInCreate: true,
    },
    {
      identifier: "searchable_type",
      label: "Role",
      accessor: "searchable_type",
      inList: true,
      type: "string",
      hideInCreate: true,
    },

    {
      identifier: "query_type",
      label: "Query Type",
      accessor: "query_type",
      inList: true,
      dependee: true,
      type: "select",
      options: [
        { value: "single", label: "Single" },
        { value: "batch", label: "Batch" },
      ],
      required: true,
    },
    {
      identifier: "query",
      label: "Query",
      accessor: "query",
      inList: false,
      type: "text",
      required: false,
    },
    {
      identifier: "success_items_count",
      hideInCreate: true,
      label: "Validation Result",
      accessor: (dataObject) => `${dataObject.success_items_count || 0}/${dataObject.total_count}`,
      inList: true,
      type: "string",
      required: true,
    },
    {
      identifier: "response_file",
      label: "Response File",
      accessor: "response_file",
      inList: false,
      type: "file",
      required: false,
      dependsOn: "query_type",
      dependsOnValue: "batch",
    },

    {
      identifier: "error_message",
      hideInCreate: true,
      label: "Error message",
      accessor: (dataObject) => (dataObject.error_message ? dataObject.error_message : "-"),
      inList: false,
      type: "string",
      required: true,
    },
  ],
  filters: [
    {
      label: { en: "Status", ar: "الحالة" },
      name: "response_status",
      multiple: false,
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "success", label: { en: "Success", ar: "" } },
          { value: "not_found", label: { en: "Not Found", ar: "" } },
          { value: "processing", label: { en: "Processing", ar: "" } },
          { value: "failed", label: { en: "Failed", ar: "" } },
        ],
      },
    },

    {
      label: { en: "Role", ar: "الوظيفة" },
      name: "searchable_type",
      multiple: false,
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "User", label: { en: "User", ar: "" } },
          { value: "Admin", label: { en: "Admin", ar: "" } },
        ],
      },
    },

    {
      label: { en: "Email", ar: "الميل" },
      name: "searchable_email",
      type: "string",
    },

    {
      label: { en: "Query Type", ar: "الحالة" },
      name: "query_type",
      multiple: false,
      type: "select",
      selectConfig: {
        optionsType: "static",
        optionsArray: [
          { value: "single", label: { en: "single", ar: "" } },
          { value: "batch", label: { en: "batch", ar: "" } },
        ],
      },
    },

    {
      label: { en: "Start Time", ar: "وقت البداية" },
      name: "start_date",
      type: "date",
    },
    {
      label: { en: "End Time", ar: "وقت النهاية" },
      name: "end_date",
      type: "date",
    },
  ],
});

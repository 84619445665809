const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV || "development") {
    case "development":
      return "http://localhost:3000/v1";
    case "staging":
      return "https://staging-vbg.gs1eg.org/v1";
    case "production":
      return "https://vbg.gs1eg.org/v1";
    default:
      return "https://staging-vbg.gs1eg.org/v1";
  }
};
export const ROLES = {
  superAdmin: "super_admin",
  admin: "admin",
};
export const API_BASE_URL = getBaseUrl();

import actions from "./actions";
import reducers from "./reducers";
import sagas from "./sagas";
import { Entity } from "@thewebops/admin-panel";
import { ROLES } from "utils/constants";

const { superAdmin, admin } = ROLES;

export default new Entity({
  name: "admin",
  pluralName: "admins",
  pathName: "admins",
  endpoint: "/admin/admins",
  serverName: "admin",
  serverPluralName: "admins",
  displayName: { en: "Admins", ar: "المدراء" },
  allowCreate: true,
  allowEdit: true,
  allowDelete: true,
  showRowActions: true,
  customActions: actions,
  customReducers: reducers,
  customSagas: sagas,
  entityIdentifier: "id",
  //authorizedRoles: {},
  rowsPerPage: 100,
  customErrorKeys: ["admin"],
  authorizedRoles: {
    entity: [superAdmin, admin],
    create: [superAdmin],
    edit: [superAdmin],
    delete: [superAdmin],
  },

  rowActions: [
    {
      label: { en: "Reset Password", ar: "Reset bel 3araby" },
      name: "sendForgetPassword",
      authorizedRoles: ["super_admin"],
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: true,
    },
    {
      identifier: "name",
      label: { en: "Name", ar: "Name bel 3araby" },
      accessor: "name",
      inList: true,
      type: "string",
      //hideInEdit: true,
      required: true,
    },
    {
      identifier: "email",
      label: "Email",
      accessor: "email",
      inList: true,
      type: "email",
      //hideInCreate: true,
      hideInEdit: true,
      required: true,
    },

    {
      identifier: "role",
      label: { en: "Role", ar: " الدور" },
      accessor: "role",
      required: true,
      type: "select",
      options: [
        {
          value: "super_admin",
          label: "Super Admin",
        },
        {
          value: "admin",
          label: "Admin",
        },
      ],
    },

    {
      identifier: "search_type",
      label: { en: "Search Type", ar: " الدور" },
      accessor: "search_type",
      required: true,
      type: "select",
      options: [
        {
          value: "vbg",
          label: "Vbg",
        },
        {
          value: "gcp",
          label: "GCP",
        },
      ],
    },

    {
      identifier: "databases_booleans",
      label: "Database access",
      accessor: "databases_booleans",
      type: "checkbox",
      checkboxConfig: {
        format: "string",
      },
      options: [
        { value: "1", label: "Gs1 Admin" },
        { value: "2", label: "Grp" },
        { value: "3", label: "Gepir" },
        { value: "4", label: "Gi" },
      ],
    },
  ],
});

import React from "react";

const SearchResponseRow = ({ value }) => {
  return typeof value === "string"
    ? value
    : Object.keys(value).map((key) => (
        <tr>
          <td>{`(${key}):`}</td>
          <td>{value[key] || <div className="unknown">Unknown</div>}</td>
        </tr>
      ));
};

export default SearchResponseRow;

import { Entity } from "@thewebops/admin-panel";
import SearchResponseLocalization from "components/SearchResponseLocalization";
import TargetMarket from "components/GtinSearchResponse/TargetMarket";
import NetContent from "components/GtinSearchResponse/NetContent";

export default new Entity({
  name: "gtinSearchResponse",
  pluralName: "gtinSearchResponses",
  pathName: "gtin_search_responses",
  endpoint: "admin/search_responses",
  serverName: "gtin_search_response",
  serverPluralName: "search_responses",
  displayName: "Search Response",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  showSearch: true,
  titleField: "name",
  entityIdentifier: "id",
  requestParams: {
    show: {
      type: "gtin",
    },
  },
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: false,
    },
    {
      identifier: "company_name",
      label: "Company Name",
      accessor: "company_name",
      component: SearchResponseLocalization,
      inList: true,
    },
    {
      identifier: "brand_name",
      label: "Brand English",
      accessor: "brand_name",
      component: SearchResponseLocalization,
      inList: true,
    },
    {
      identifier: "product_description",
      label: "Product Description",
      accessor: "product_description",
      component: SearchResponseLocalization,
      inList: true,
    },
    {
      identifier: "net_content",
      accessor: "net_content",
      component: NetContent,
      label: "Net Content",
      inList: true,
    },
    {
      identifier: "product_image",
      accessor: "product_image",
      type: "string",
      label: "Product Image",
      inList: true,
    },

    {
      identifier: "database_type",
      accessor: "database_type",
      type: "string",
      label: "Database Type",
      inList: true,
    },
    {
      identifier: "status",
      accessor: "status",
      type: "string",
      label: "Status",
      inList: true,
    },
    {
      identifier: "unit_of_measure",
      accessor: "unit_of_measure",
      type: "string",
      label: "Unit Of Measure",
      inList: true,
    },
    {
      identifier: "licensee_gln",
      accessor: "licensee_gln",
      type: "string",
      label: "Licensee Gln",
      inList: true,
    },
    {
      identifier: "gtin",
      accessor: "gtin",
      type: "string",
      label: "gtin",
      inList: true,
    },
    {
      identifier: "mo_name",
      accessor: "mo_name",
      type: "string",
      label: "Mo Name",
      inList: true,
    },
    {
      identifier: "target_market",
      accessor: "target_market",
      label: "Target Market",
      component: TargetMarket,
      inList: true,
    },
    {
      identifier: "error_message",
      accessor: "error_message",
      type: "json",
      label: "Error Message",
      inList: false,
    },
  ],
});

import { Entity } from "@thewebops/admin-panel";
import { push } from "connected-react-router";
import SearchResponseLocalization from "components/SearchResponseLocalization";

export default new Entity({
  name: "searchResponse",
  pluralName: "searchResponses",
  pathName: "search_responses",
  endpoint: "search_responses",
  serverName: "search_response",
  serverPluralName: "search_responses",
  displayName: "Search Response",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: true,
  showSearch: true,
  titleField: "name",
  entityIdentifier: "id",
  rowActions: [
    {
      label: { en: "Show" },
      name: "show",
      customFunction: (dispatch, item) => {
        dispatch(push(`/admin/${item.type}_search_responses/${item.id}`));
      },
    },
  ],
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: false,
      hideInCreate: true,
      hideInEdit: false,
    },
    {
      identifier: "company_name",
      accessor: "company_name",
      component: SearchResponseLocalization,
      label: "Company Name",
      inList: true,
    },
    {
      identifier: "database_type",
      accessor: "database_type",
      type: "string",
      label: "Database Type",
      inList: true,
    },
    {
      identifier: "status",
      accessor: "status",
      type: "string",
      label: "Status",
      inList: true,
    },
    {
      identifier: "unit_of_measure",
      accessor: "unit_of_measure",
      type: "string",
      label: "Unit Of Measure",
      inList: true,
    },
    {
      identifier: "licensee_gln",
      accessor: "licensee_gln",
      type: "string",
      label: "Licensee Gln",
      inList: true,
    },
    {
      identifier: "gtin",
      accessor: "gtin",
      type: "string",
      label: "gtin",
      inList: true,
    },
    {
      identifier: "mo_name",
      accessor: "mo_name",
      type: "string",
      label: "Mo Name",
      inList: true,
    },
    {
      identifier: "error_message",
      accessor: "error_message",
      type: "json",
      label: "Error Message",
      inList: false,
    },
  ],
});

import { Entity } from "@thewebops/admin-panel";
import SearchResponseLocalization from "components/SearchResponseLocalization";

export default new Entity({
  name: "gcpSearchResponse",
  pluralName: "gcpSearchResponse",
  pathName: "gcp_search_responses",
  endpoint: "admin/search_responses",
  serverName: "gcp_search_response",
  serverPluralName: "search_responses",
  displayName: "GCP Search Response",
  allowCreate: false,
  allowEdit: false,
  allowDelete: false,
  showRowActions: false,
  showSearch: true,
  titleField: "name",
  requestParams: {
    show: {
      type: "gcp",
    },
  },
  entityIdentifier: "id",
  fields: [
    {
      identifier: "id",
      label: "ID",
      accessor: "id",
      inList: true,
      usedToShow: true,
      hideInCreate: true,
      hideInEdit: false,
    },
    {
      identifier: "company_name",
      label: "Company Name",
      accessor: "company_name",
      component: SearchResponseLocalization,
      inList: true,
    },
    {
      identifier: "database_type",
      accessor: "database_type",
      type: "string",
      label: "Database Type",
      inList: true,
    },
    {
      identifier: "status",
      accessor: "status",
      type: "string",
      label: "Status",
      inList: true,
    },
    {
      identifier: "unit_of_measure",
      accessor: "unit_of_measure",
      type: "string",
      label: "Unit Of Measure",
      inList: true,
    },
    {
      identifier: "licensee_gln",
      accessor: "licensee_gln",
      type: "string",
      label: "Licensee Gln",
      inList: true,
    },
    {
      identifier: "gtin",
      accessor: "gtin",
      type: "string",
      label: "gtin",
      inList: true,
    },
    {
      identifier: "mo_name",
      accessor: "mo_name",
      type: "string",
      label: "Mo Name",
      inList: true,
    },
    {
      identifier: "error_message",
      accessor: "error_message",
      type: "json",
      label: "Error Message",
      inList: true,
    },
  ],
});
